import auth from '@/middleware/auth.js'

export default [
  {
      path: '/formalizacion/v1/notarios',
      name: 'formalizacion_notarios',
      component: () =>
          import ('@/apps/formalizacion/pages/V1/Notarios/NotariosPage'),
      meta: {
          title: 'Notarios - Formalización',
          middleware: auth
      }
  }, {
      path: '/formalizacion/v1/notario/solicitud/:solicitud',
      name: 'formalizacion_notario_solicitud',
      component: () =>
          import ('@/apps/formalizacion/pages/V1/Notarios/DetallesNotario.vue'),
      meta: {
          title: 'Solicitud - Notario - Formalización',
          middleware: auth
      }
  }, {
      path: '/formalizacion/v1/valuadores',
      name: 'formalizacion_valuadores',
      component: () =>
          import ('@/apps/formalizacion/pages/V1/Avaluo/ValuadoresPage'),
      meta: {
          title: 'Notarios - Formalización',
          middleware: auth
      }
  }, {
      path: '/formalizacion/v1/avaluo/solicitud/:solicitud',
      name: 'formalizacion_avaluo_solicitud',
      component: () =>
          import ('@/apps/formalizacion/pages/V1/Avaluo/DetallesValuador.vue'),
      meta: {
          title: 'Solicitud - Avaluo - Formalización',
          middleware: auth
      }
  }, {
      path: '/formalizacion/v1/administracion/usuarios',
      name: 'formalizacion_administracion_usuarios',
      component: () =>
          import ('@/apps/formalizacion/pages/V1/Administracion/Usuario.vue'),
      meta: {
          title: 'Administración - Formalización',
          middleware: auth
      }
  }, {
      path: '/formalizacion/v1/administracion/empresas',
      name: 'formalizacion_administracion_empresas',
      component: () =>
          import ('@/apps/formalizacion/pages/V1/Administracion/Empresa.vue'),
      meta: {
          title: 'Empresas - Formalización',
          middleware: auth
      }
  }
]